// @ts-nocheck
import queryString from 'query-string'
import { CONSTANTS, ORDER_TYPES_QUERY_PARAMS } from 'utils/constants'
import { getTranslatedTextByKey } from './language'
import { type Banner } from 'components/deals/Banners'
import type { NextRouter } from 'next/router'
import type CouponFlowStore from 'mobx/CouponFlow'
import { navigateToMenuSection } from 'utils/menu/menuUtils'
import type CartStore from 'mobx/Cart/store'
import type { SetStore } from 'contexts/StoreContext'
import type AddressManagerV2Store from 'mobx/AddressManagerV2/store'
import User from 'mobx/User'

export const getQueryStringAndCouponCode = (urlQs = '') => {
	const isCouponCodeIncludedInQs = urlQs.includes('#cc_') || urlQs.includes('#item_')
	let queryParamsString = urlQs
	let couponCode = ''

	if (isCouponCodeIncludedInQs) {
		const indexOfLastHash = urlQs.lastIndexOf('#')
		queryParamsString = urlQs.slice(0, indexOfLastHash)
		couponCode = urlQs.slice(indexOfLastHash)
	}
	return { queryParamsString, couponCode }
}

export const getDealRedirectQueryString = (queryParamsString, couponCode) => {
	// Sometimes urlQs (comes from server) includes the coupon code at the end like ('...CINNAMON TWISTS#cc_4b266952')

	const queryParams = queryString.parse(queryParamsString)
	const { logo: img, title, ...restQueryParams } = queryParams
	const newQueryParams = img
		? {
				img,
				title: title || getTranslatedTextByKey('webviewFlow.specialOffer', 'Special Offer!'),
				...restQueryParams,
		  }
		: { title: title || getTranslatedTextByKey('webviewFlow.specialOffer', 'Special Offer!'), ...restQueryParams }
	const newQueryParamsPath = new URLSearchParams(newQueryParams).toString()
	return `${newQueryParamsPath}${couponCode ? `&${couponCode}` : ''}`
}

export const handleBannerByTarget = ({
	banner,
	router,
	couponFlowStore,
	setStore,
	cartStore,
	addressManagerV2Store
}: {
	banner: Banner
	router: NextRouter
	couponFlowStore: CouponFlowStore
	setStore: SetStore
	cartStore: CartStore
	addressManagerV2Store: AddressManagerV2Store
}) => {
	if (!banner?.target) {
		return
	}
	const { target, refName, refId, urlQs } = banner

	const actions: Record<string, () => void> = {
		[CONSTANTS.DEAL_TARGETS.COUPON]: () => refName && couponFlowStore.start({ code: refName, orderTypeToForce: null, openModal: false }),
		[CONSTANTS.DEAL_TARGETS.ITEM]: () => refId && couponFlowStore.start({ code: refId, orderTypeToForce: null, openModal: false }),
		[CONSTANTS.DEAL_TARGETS.MENU_SECTION]: () => refId && navigateToMenuSection(refId, refName, setStore, cartStore, router, addressManagerV2Store),
		[CONSTANTS.DEAL_TARGETS.COUPON_WALLET]: () => router.push(`/${User.preferredLanguage}/coupons`),
		[CONSTANTS.DEAL_TARGETS.EXTERNAL_LINK]: () => refId && window.open(refId, '_blank'),
		[CONSTANTS.DEAL_TARGETS.LP]: () => {
			const { queryParamsString, couponCode } = getQueryStringAndCouponCode(urlQs)
			const dealRedirectQueryString = getDealRedirectQueryString(queryParamsString, couponCode)
			router.push(`/?${dealRedirectQueryString}`)
		},
	}

	actions[target]?.()
}

export const validateDealIfLocalized = (deal, isDelivery) => {
	if (!deal?.orderTypes?.length) {
		return true
	}

	const { urlQs, orderTypes } = deal
	const { DELIVERY_CLASS, PEAK_UP_CLASS } = ORDER_TYPES_QUERY_PARAMS
	const { DELIVERY, PEAKUP, PICKUP } = CONSTANTS.ORDER_TYPE

	return isDelivery
		? urlQs?.includes(DELIVERY_CLASS) || orderTypes?.includes(DELIVERY)
		: urlQs?.includes(PEAK_UP_CLASS) || orderTypes?.some((type) => [PEAKUP, PICKUP].includes(type))
}
